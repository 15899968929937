(function(doc){
  var scriptElm = doc.scripts[doc.scripts.length - 1];
  var warn = ['[ionicons] Deprecated script, please remove: ' + scriptElm.outerHTML];

  warn.push('To improve performance it is recommended to set the differential scripts in the head as follows:')

  var parts = scriptElm.src.split('/');
  parts.pop();
  parts.push('ionicons');
  var url = parts.join('/');

  var scriptElmModule = doc.createElement('script'); // Use a different variable name here
  scriptElmModule.setAttribute('type', 'module');
  scriptElmModule.src = url + '/ionicons.esm.js';
  warn.push(scriptElmModule.outerHTML);
  scriptElmModule.setAttribute('data-stencil-namespace', 'ionicons');
  doc.head.appendChild(scriptElmModule);

  
  var scriptElmNomodule = doc.createElement('script'); // Use a different variable name here
  scriptElmNomodule.setAttribute('nomodule', '');
  scriptElmNomodule.src = url + '/ionicons.js';
  warn.push(scriptElmNomodule.outerHTML);
  scriptElmNomodule.setAttribute('data-stencil-namespace', 'ionicons');
  doc.head.appendChild(scriptElmNomodule);
  
  console.warn(warn.join('\n'));

})(document);
