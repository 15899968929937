import React, { useEffect, useRef, useState } from 'react'
import banner1 from "../../img/new/banner_shap1.png"
import banner2 from "../../img/new/banner_shap2.png"
import banner3 from "../../img/new/banner_shap3.png"
import banner4 from "../../img/new/banner_shap4.png"
import pulse1 from "../../img/new/plus1.png"
import pulse2 from "../../img/new/plus2.png"
import shapeWhite from "../../img/new/shap_white.png"
import icon1 from "../../img/new/icon1.png"
import icon2 from "../../img/new/icon2.png"
import icon3 from "../../img/new/icon3.png"
import icon4 from "../../img/new/icon4.png"
import icon5 from "../../img/new/icon5.png"
import icon6 from "../../img/new/icon6.png"
import icon7 from "../../img/new/icon7.png"
import icon8 from "../../img/new/icon8.png"
import icon9 from "../../img/new/icon9.png"
import shape from "../../img/new/shap.png"
import smile from "../../img/new/smile.png"
import house from "../../img/new/house.png"
import doc from "../../img/new/doc.png"
import bag from "../../img/new/bag.png"
import shape1 from "../../img/new/community_bg_shap_one.png"
import shape2 from "../../img/new/community_bg_shap_two.png"
import image2 from "../../img/new/img_02.png"
import image3 from "../../img/new/img_03.png"
import image4 from "../../img/new/img_04.png"
import sign from "../../img/new/sign.png"
import dot from "../../img/new/dot.png"
import feedback1 from "../../img/new/feedback_img.jpg"
import feedback2 from "../../img/new/feedback_img_02.jpg"
import feedback3 from "../../img/new/feedback_img_03.jpg"
import feedback4 from "../../img/new/feedback_img_04.jpg"
import subshape1 from "../../img/new/subscribe_shap.png"
import subshape2 from "../../img/new/subscribe_shap_two.png"

import "../../assets/bootstrap/css/bootstrap.min.css"
import "../../assets/slick/slick.css"
import "../../assets/slick/slick-theme.css"
import "../../assets/elagent-icon/style.css"
import "../../assets/niceselectpicker/nice-select.css"
import "../../assets/mcustomscrollbar/jquery.mCustomScrollbar.min.css"
import "../../assets/css/style-main.css"
import "../../assets/css/responsive.css"

/* eslint-disable no-unused-expressions */
import $ from 'jquery';
window.jQuery = window.$ = $;
require("jquery-nice-select");
import 'popper.js';
import Slider from "react-slick";
/* eslint-enable no-unused-expressions */

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
const index = () => {
    const [testiSettings, setTestiSettings] = useState(null);
    const [testiImgSettings, setTestiImgSettings] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    var testimonialSettings = {
        // dots: true,
        // infinite: true,
        // speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        speed: 1000,
        dots: true,
        arrows: false,
        // asNavFor: ".doc_img_slider",
    };

    var testimonialImgSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        // asNavFor: ".doc_testimonial_slider",
        arrows: false,
        fade: true,
        focusOnSelect: true,
    }

    useEffect(() => {
        $('#search_post_type').niceSelect();
        setTestiSettings(sliderRef1)
        setTestiImgSettings(sliderRef2)
    }, [])

    return (
        <>
            <div className="body_wrapper">
                <Navbar/>
                <section className="doc_banner_area banner_creative1">
                    <ul className="list-unstyled banner_shap_img">
                        <li><img src={banner4} alt="" /></li>
                        <li><img src={banner1} alt="" /></li>
                        <li><img src={banner3} alt="" /></li>
                        <li><img src={banner2} alt="" /></li>
                        <li><img data-parallax='{"x": -180, "y": 80, "rotateY":2000}' src={pulse1} alt="" /></li>
                        <li><img data-parallax='{"x": -50, "y": -160, "rotateZ":200}' src={pulse2} alt="" /></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="container">
                        <div className="doc_banner_content">
                            <h2 className="wow fadeInUp">How can we help you?</h2>
                            <p className="wow fadeInUp" data-wow-delay="0.2s">Search here to get answers to your questions</p>
                            <form action="#" className="header_search_form">
                                <div className="header_search_form_info">
                                    <div className="form-group">
                                        <div className="input-wrapper">
                                            <i className="icon_search"></i>
                                            <input type='search' id="searchbox" autoComplete="off" name="search"
                                                placeholder="Search the Doc" />
                                            <div className="header_search_form_panel">
                                                <ul className="list-unstyled">
                                                    <li>Help Desk
                                                        <ul className="list-unstyled search_item">
                                                            <li><span>Configuration</span><a href="#">How to edit host and
                                                                port?</a></li>
                                                            <li><span>Configuration</span><a href="#">The dev Property</a></li>
                                                        </ul>
                                                    </li>
                                                    <li>Support
                                                        <ul className="list-unstyled search_item">
                                                            <li><span>Pages</span><a href="#">The asyncData Method</a></li>
                                                        </ul>
                                                    </li>
                                                    <li>Documentation
                                                        <ul className="list-unstyled search_item">
                                                            <li><span>Getting Started</span><a href="#">The asyncData Method</a>
                                                            </li>
                                                            <li><span>Getting Started</span><a href="#">The asyncData Method</a>
                                                            </li>
                                                            <li><span>Getting Started</span><a href="#">The asyncData Method</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                            <select className="search-expand-types custom-select" name="post_type"
                                                id="search_post_type">
                                                <option value="">All Docs </option>
                                                <option value="manual_kb">Knowledge Base</option>
                                                <option value="manual_documentation">Documentation</option>
                                                <option value="manual_faq">FAQs</option>
                                                <option value="forum">Forums</option>
                                                <option value="manual_portfolio">Portfolio</option>
                                                <option value="product">Products</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="header_search_keyword">
                                    <span className="header-search-form__keywords-label">Suggested Search:</span>
                                    <ul className="list-unstyled">
                                        <li className="wow fadeInUp" data-wow-delay="0.2s"><a href="#">guest users</a></li>
                                        <li className="wow fadeInUp" data-wow-delay="0.3s"><a href="#">invoice</a></li>
                                        <li className="wow fadeInUp" data-wow-delay="0.4s"><a href="#">security</a></li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <section className="doc_features_area">
                    <img className="doc_features_shap" src={shapeWhite} alt="" />
                    <div className="container">
                        <div className="doc_features_inner">
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="0.5s">
                                <img src={icon1} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Knowledge Base</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.6s">
                                <img src={icon2} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Community Forums</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s">
                                <img src={icon3} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Documentation</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="0.8s">
                                <img src={icon4} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Working with Docs</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="0.9s">
                                <img src={icon5} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Getting Started</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
                                <img src={icon6} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Account Management</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="0.7s">
                                <img src={icon7} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Productivity</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="0.8s">
                                <img src={icon8} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Docs in Help Scout</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="media doc_features_item wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="0.9s">
                                <img src={icon9} alt="" />
                                <div className="media-body">
                                    <a href="#">
                                        <h4>Formatting Content</h4>
                                    </a>
                                    <p>245 Posts</p>
                                </div>
                            </div>
                            <div className="see_more_item collapse-wrap">
                                <div className="media doc_features_item">
                                    <img src={icon7} alt="" />
                                    <div className="media-body">
                                        <a href="#">
                                            <h4>Productivity</h4>
                                        </a>
                                        <p>245 Posts</p>
                                    </div>
                                </div>
                                <div className="media doc_features_item">
                                    <img src={icon8} alt="" />
                                    <div className="media-body">
                                        <a href="#">
                                            <h4>Docs in Help Scout</h4>
                                        </a>
                                        <p>245 Posts</p>
                                    </div>
                                </div>
                                <div className="media doc_features_item">
                                    <img src={icon9} alt="" />
                                    <div className="media-body">
                                        <a href="#">
                                            <h4>Formatting Content</h4>
                                        </a>
                                        <p>245 Posts</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="recommended_topic_area">
                    <div className="container">
                        <div className="recommended_topic_inner">
                            <img className="doc_shap_one" src={shape} alt="" />
                            <div className="doc_round one" data-parallax='{"x": -80, "y": -100, "rotateY":0}'></div>
                            <div className="doc_round two" data-parallax='{"x": -10, "y": 70, "rotateY":0}'></div>
                            <div className="doc_title text-center">
                                <h2 className="wow fadeInUp" data-wow-delay="0.2s">Recommended Topics</h2>
                                <p className="wow fadeInUp" data-wow-delay="0.4s">Loaded with awesome features like Documentation,
                                    Knowledgebase,<br /> Forum & more!</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-6">
                                    <div className="recommended_item wow fadeInUp" data-wow-delay="0.2s">
                                        <img src={smile} alt="" />
                                        <a href="#">
                                            <h3>Getting Started</h3>
                                        </a>
                                        <ul className="list-unstyled">
                                            <li><a href="#">Welcome to Ghost</a></li>
                                            <li><a href="#">Writing posts with Ghost</a></li>
                                            <li><a href="#">Publishing options</a></li>
                                            <li><a href="#">Managing admin settings</a></li>
                                            <li><a href="#">Organising your content</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="recommended_item wow fadeInUp" data-wow-delay="0.3s">
                                        <img src={house} alt="" />
                                        <a href="#">
                                            <h3>Advanced Usage</h3>
                                        </a>
                                        <ul className="list-unstyled">
                                            <li><a href="#">Getting started</a></li>
                                            <li><a href="#">Become a Pro</a></li>
                                            <li><a href="#">Admin & Billing</a></li>
                                            <li><a href="#">Mobile App</a></li>
                                            <li><a href="#">Guides</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="recommended_item wow fadeInUp" data-wow-delay="0.4s">
                                        <img src={doc} alt="" />
                                        <a href="#">
                                            <h3>Knowledge Base</h3>
                                        </a>
                                        <ul className="list-unstyled">
                                            <li><a href="#">Organising content in Ghost</a></li>
                                            <li><a href="#">Using the editor</a></li>
                                            <li><a href="#">General publication settings</a></li>
                                            <li><a href="#">Publishing Options</a></li>
                                            <li><a href="#">Knowledge Base</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="recommended_item wow fadeInUp" data-wow-delay="0.5s">
                                        <img src={bag} alt="" />
                                        <a href="#">
                                            <h3>User Settings</h3>
                                        </a>
                                        <ul className="list-unstyled">
                                            <li><a href="#">How do I reset my password</a></li>
                                            <li><a href="#">Managing your team</a></li>
                                            <li><a href="#">Can I add my social accounts</a></li>
                                            <li><a href="#">Imports and exports</a></li>
                                            <li><a href="#">Design Settings</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
                                <a href="#" className="question_text">Want to know more or have a<br /> Question?
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="doc_community_area">
                    <img className="shap_one" src={shape1} alt="" />
                    <img className="shap_two" src={shape2} alt="" />
                    <div className="container">
                        <div className="doc_title text-center">
                            <h2 className="wow fadeInUp" data-wow-delay="0.2s">How It works</h2>
                            <p className="wow fadeInUp" data-wow-delay="0.4s">Learn languages online with the world&apos;s best tutors
                            </p>
                        </div>
                        <div className="doc_community_info">
                            <div className="doc_community_item wow fadeInUp" data-wow-delay="0.2s">
                                <div className="doc_community_icon">
                                    <i className="icon_folder-alt"></i>
                                </div>
                                <div className="doc_entry_content">
                                    <a href="#">
                                        <h4>The Intercom Messenger</h4>
                                    </a>
                                    <p>Setting up and customizing your Messenger to start chatting with customers</p>
                                    <div className="doc_entry_info">
                                        <ul className="list-unstyled author_avatar">
                                            <li><img src={image2} alt="" /></li>
                                            <li><img src={image3} alt="" /></li>
                                            <li><img src={image4} alt="" /></li>
                                            <li>+2</li>
                                        </ul>
                                        <div className="text">
                                            76 articles in this collection<br /> Written by Ruairí Galavan, Jack Jenkins, DJ and 7
                                            others
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="doc_community_item wow fadeInUp" data-wow-delay="0.3s">
                                <div className="doc_community_icon">
                                    <i className="icon_comment_alt"></i>
                                </div>
                                <div className="doc_entry_content">
                                    <a href="#">
                                        <h4>The Intercom Messenger</h4>
                                    </a>
                                    <p>Setting up and customizing your Messenger to start chatting with customers</p>
                                    <div className="doc_entry_info">
                                        <ul className="list-unstyled author_avatar">
                                            <li><img src={image2} alt="" /></li>
                                            <li><img src={image3} alt="" /></li>
                                            <li><img src={image4} alt="" /></li>
                                            <li>+2</li>
                                        </ul>
                                        <div className="text">
                                            76 articles in this collection<br /> Written by Ruairí Galavan, Jack Jenkins, DJ and 7
                                            others
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="doc_community_item wow fadeInUp" data-wow-delay="0.4s">
                                <div className="doc_community_icon">
                                    <i className=" icon_lightbulb_alt"></i>
                                </div>
                                <div className="doc_entry_content">
                                    <a href="#">
                                        <h4>The Intercom Messenger</h4>
                                    </a>
                                    <p>Setting up and customizing your Messenger to start chatting with customers</p>
                                    <div className="doc_entry_info">
                                        <ul className="list-unstyled author_avatar">
                                            <li><img src={image2} alt="" /></li>
                                            <li><img src={image3} alt="" /></li>
                                            <li><img src={image4} alt="" /></li>
                                            <li>+2</li>
                                        </ul>
                                        <div className="text">
                                            76 articles in this collection<br /> Written by Ruairí Galavan, Jack Jenkins, DJ and 7
                                            others
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center wow fadeInUp" data-wow-delay="0.4s">
                                <a href="#" className="question_text">Contact us and we’ll get back to you<br />
                                    as soon as we can.
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="doc_testimonial_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <Slider {...testimonialSettings} asNavFor={testiImgSettings} ref={slider => (sliderRef1 = slider)} className="doc_testimonial_slider">
                                    <div className="item">
                                        <h3>Tinkety tonk old fruit victoria sponge squiffy bleeder twit the bee&apos;s knees loo
                                            David, buggered haggle pear shaped bubble and squeak.”</h3>
                                        <div className="name">
                                            <h5>Penny Tool,</h5>
                                            <span>Director of Sales and Success</span>
                                        </div>
                                        <a href="#" className="sign"><img src={sign} alt="" /></a>
                                    </div>
                                    <div className="item">
                                        <h3>Hendrerit laoreet incidunt molestie eum placeat, neque ridiculus? Maecenas
                                            incididunt aperiam tempora cumque quos?”</h3>
                                        <div className="name">
                                            <h5>Penny Tool,</h5>
                                            <span>Director of Sales and Success</span>
                                        </div>
                                        <a href="#" className="sign"><img src={sign} alt="" /></a>
                                    </div>
                                    <div className="item">
                                        <h3>Curabitur vitae dignissimos pulvinar eligendi ullamcorper, laoreet, accusantium
                                            numquam habitant quaerat minim consequatur”</h3>
                                        <div className="name">
                                            <h5>Penny Tool,</h5>
                                            <span>Director of Sales and Success</span>
                                        </div>
                                        <a href="#" className="sign"><img src={sign} alt="" /></a>
                                    </div>
                                    <div className="item">
                                        <h3>Tinkety tonk old fruit victoria sponge squiffy bleeder twit the bee&apos;s knees loo
                                            David, buggered haggle pear shaped bubble and squeak.”</h3>
                                        <div className="name">
                                            <h5>Penny Tool,</h5>
                                            <span>Director of Sales and Success</span>
                                        </div>
                                        <a href="#" className="sign"><img src={sign} alt="" /></a>
                                    </div>
                                </Slider>
                            </div>
                            <div className="col-lg-6">
                                <Slider {...testimonialImgSettings} asNavFor={testiSettings}
                                    ref={slider => (sliderRef2 = slider)} className="doc_img_slider">
                                    <div className="item">
                                        <img className="dot" src={dot} alt="" />
                                        <div className="round one"></div>
                                        <div className="round two"></div>
                                        <img src={feedback1} alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="dot" src={dot} alt="" />
                                        <div className="round one"></div>
                                        <div className="round two"></div>
                                        <img src={feedback2} alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="dot" src={dot} alt="" />
                                        <div className="round one"></div>
                                        <div className="round two"></div>
                                        <img src={feedback3} alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="dot" src={dot} alt="" />
                                        <div className="round one"></div>
                                        <div className="round two"></div>
                                        <img src={feedback4} alt="" />
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="doc_subscribe_area">
                    <div className="container">
                        <div className="doc_subscribe_inner">
                            <img className="one" src={subshape1} alt="" />
                            <img className="two" src={subshape2} alt="" />
                            <div className="text wow fadeInLeft" data-wow-delay="0.2s">
                                <h2>Great Customer <br />Relationships start here</h2>
                            </div>
                            <form action="#" className="doc_subscribe_form wow fadeInRight mailchimp" data-wow-delay="0.4s"
                                method="post">
                                <div className="form-group">
                                    <div className="input-fill">
                                        <input type="email" name="EMAIL" id="email" className="memail"
                                            placeholder="Your work email" />
                                    </div>
                                    <button type="submit" className="submit_btn">Get started</button>
                                    <p className="mchimp-errmessage" style={{ "display": "none" }}></p>
                                    <p className="mchimp-sucmessage" style={{ "display": "none" }}></p>
                                </div>
                                <ul className="list-unstyled">
                                    <li><a href="#">Messenger</a></li>
                                    <li><a href="#">Product Tours</a></li>
                                    <li><a href="#">Inbox and more</a></li>
                                </ul>

                            </form>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default index
