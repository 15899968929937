import React from 'react'
import logo from "../img/logo.png"
import logo2 from "../img/logo-w.png"
import logo2px from "../img/logo-w2x.png"
import smile2 from "../img/new/smile2.png"
import doc2 from "../img/new/doc2.png"
import house2 from "../img/new/house2.png"
import bag2 from "../img/new/bag2.png"
import PropTypes from "prop-types";
import { Link, useParams } from 'react-router-dom'

const Footer = ({ data }) => {
    return (
        <>
            <footer className="doc_footer_area">
                <div className="doc_footer_top bg-transparent">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="f_widget doc_about_widget wow fadeInUp" data-wow-delay="0.2s">
                                    <a style={{ backgroundColor: "black", width: '100%' }}>
                                        <img src={data?.profile_image} height={150} alt="" />
                                    </a>
                                    <ul className="list-unstyled">
                                        <li><a href={data?.socail_links?.facebook}><i className="social_facebook"></i></a></li>
                                        <li><a href={data?.socail_links?.twitter}><i className="social_twitter"></i></a></li>
                                        <li><a href={data?.socail_links?.facebook}><i className="social_instagram"></i></a></li>
                                        <li><a href={data?.socail_links?.linkedin}><i className="social_linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="f_widget doc_service_list_widget pl-30 wow fadeInUp" data-wow-delay="0.3s">
                                    <h3 className="f_title_two">Pages</h3>
                                    <ul className="list-unstyled">
                                        <Link to={`/docs`} style={{ color: 'inherit' }} className="nav-link">Docs</Link>
                                        <Link style={{ color: 'inherit' }} to={`/faqs`} className="nav-link">Faqs</Link>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-sm-6">
                                <div className="f_widget doc_service_list_widget pl-100 wow fadeInUp" data-wow-delay="0.4s">
                                    <h3 className="f_title_two">Support</h3>
                                    <ul className="list-unstyled">
                                        <li><a href="#">Help Desk</a></li>
                                        <li><a href="#">Knowledge Base</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-4 col-sm-6">
                                <div className="f_widget doc_service_list_widget pl-70 wow fadeInUp" data-wow-delay="0.5s">
                                    <h3 className="f_title_two">Company</h3>
                                    <ul className="list-unstyled">
                                        <li>{data?.personalinfo?.company_name}</li>
                                        <li>{data?.personalinfo?.phone}</li>
                                        <li>{data?.personalinfo?.email}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="doc_footer_bottom">
                    <div className="container d-flex justify-content-between">
                        <ul className="doc_footer_menu list-unstyled wow fadeInUp" data-wow-delay="0.2s">
                            <li><a href="#">Blog</a></li>
                            <li><a href="#">Latest Projects</a></li>

                        </ul>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">© 2023 All Rights Reserved Design

                        </p>
                    </div>
                </div> */}
            </footer>
        </>
    )
}

export default Footer
Footer.propTypes = {
    data: PropTypes.object.isRequired,
};