import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/bootstrap/css/bootstrap.min.css";
import "./css/style-main.css";
import "./css/responsive.css";
// import "./css/style-main.css.map"
import "./assets/animation/animate.css";
import "./assets/elagent-icon/style.css";
import "./assets/ionicons/ionicons";
import Home from "./pages/home/index";
import Contact from "./pages/contact/index";
import Signin from "./pages/signin/Index";
import Signup from "./pages/signup/Index";
import ErrorPage from "./pages/404Page/Index";
import ForumRoot from "./pages/forumRoot/Index";
import ForumTopics from "./pages/forumTopics/Index";
import ForumDetails from "./pages/forumDetails/Index";
import Docs from "./pages/Docs/index";
import DocsDetails from "./pages/Docs/components/DocsDetails";
import DocsList from "./pages/Docs/components/DocsList";
import UserProfile from "./pages/userProfile/Index";

import { BrowserRouter as Router, Routes, Route, useParams, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Faqs from "./pages/Faqs/index";
import { useAppServices } from "./hook/services";
import Loader from "./components/Loader";
function App() {
  const AppService = useAppServices()
  const [agency, setAgency] = useState({})
  const [loader, setloader] = useState(true)
  const changeFavicon = (data) => {
    // Replace 'new-favicon.ico' with the path to your new favicon
    document.title = data?.personalinfo?.company_name;
    const newFavicon = data?.profile_image;

    // Create a new link element
    const link = document.createElement("link");
    link.rel = "icon";
    link.type = "image/x-icon";
    link.href = newFavicon;

    // Find the existing favicon link element in the head
    const head = document.head || document.getElementsByTagName("head")[0];
    const existingFavicon = document.querySelector("link[rel*='icon']");

    // If an existing favicon is found, remove it
    if (existingFavicon) {
      head.removeChild(existingFavicon);
    }

    // Append the new favicon link element to the head
    head.appendChild(link);
  };
  const getProfile = async () => {
    const domain = window.location.hostname
    // const domain = 'test.levelupmarketplace.io'
    const { response } = await AppService.agency.get({
      query: `domain=${domain}`
    });
    console.log(response, 'getProfile')
    if (response) {
      setAgency(response.data)
      changeFavicon(response.data?.profile || {})
      setloader(false)
    } else {
      setloader(false)
    }
  }
  const onLoad = async () => {

    getProfile()
  }
  useEffect(() => {
    onLoad()
  }, [window.location.hostname]);
  return (
    <>
      {
        loader ?
          <Loader />
          :
          <Router>
            <Routes>
              {/* <Route exact path="/" element={<Home />}></Route>
              <Route exact path="/contact" element={<Contact />}></Route> */}
              <Route exact path="/faqs" element={<Faqs agency={agency} />}></Route>
              <Route exact path="/docs" element={<Docs agency={agency} />}></Route>
              <Route
                exact
                path="/docs/docs-details/:doc_id"
                element={<DocsDetails />}
              ></Route>
              <Route
                exact
                path="/docs/docs-list/:sub_category_id"
                element={<DocsList />}
              ></Route>
              <Route path="*" element={<Navigate to="/docs" />} />
              {/* <Route exact path="/signin" element={<Signin />}></Route>
              <Route exact path="/signup" element={<Signup />}></Route>
              <Route exact path="/404" element={<ErrorPage />}></Route>
              <Route exact path="/forumroot" element={<ForumRoot />}></Route>
              <Route exact path="/forumtopics" element={<ForumTopics />}></Route>
              <Route exact path="/forumdetails" element={<ForumDetails />}></Route>
              <Route exact path="/userprofile" element={<UserProfile />}></Route> */}
            </Routes>
          </Router>
      }
    </>
  );
}

export default App;
