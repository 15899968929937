import React, { createContext, useContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import { useAppServices } from "../hook/services";

const LanguageContext = createContext();
LanguageContext.displayName = "LanguageContext";

function LanguageProvider({ children }) {
  const AppService = useAppServices()
  const [language, setLanguage] = useState("english");
  const [agency, setAgency] = useState({});
  const [loader, setLoader] = useState(true);
  const handleLanguage = (e) => {
    setLanguage(e.target.value);
  };
  const getAgency = async () => {
    const domain = window.location.hostname
    // const domain = 'test.levelupmarketplace.io'
    const { response } = await AppService.agency.get({
      query: `domain=${domain}`
    });
    if (response) {
      setAgency(response.data)
      console.log(response, 'responseresponse')
      if (response.data?.app_data?.language) {
        setLanguage(response.data?.app_data?.language)
        // if (response.data?.app_data?.language == "66828c06c156ac55abc3a264") {
        //   setLanguage("english")
        // } else {

        //   setLanguage(response.data?.app_data?.language)
        // }
      }
      setLoader(false)
    } else {
      setLoader(false)
    }
  }
  const onLoad = async () => {
    getAgency()
  }
  useEffect(() => {
    onLoad()
  }, []);
  const value = useMemo(() => [language, handleLanguage, agency], [language, handleLanguage, agency]);

  return loader ? (
    <Loader />
  ) : (
    <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
  );
}

function useLanguageInfo() {
  return useContext(LanguageContext) || [];
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LanguageProvider, useLanguageInfo };
