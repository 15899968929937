import React, { useEffect, useRef, useState } from 'react'
import banner1 from "../img/new/banner_shap1.png"
import banner2 from "../img/new/banner_shap2.png"
import banner3 from "../img/new/banner_shap3.png"
import banner4 from "../img/new/banner_shap4.png"
import pulse1 from "../img/new/plus1.png"
import pulse2 from "../img/new/plus2.png"

import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/slick/slick.css"
import "../assets/slick/slick-theme.css"
import "../assets/elagent-icon/style.css"
import "../assets/niceselectpicker/nice-select.css"
import "../assets/mcustomscrollbar/jquery.mCustomScrollbar.min.css"
import "../assets/css/style-main.css"
import "../assets/css/responsive.css"

const Banner = () => {
  const [testiSettings, setTestiSettings] = useState(null);
  const [testiImgSettings, setTestiImgSettings] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  var testimonialSettings = {
    // dots: true,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    speed: 1000,
    dots: true,
    arrows: false,
    // asNavFor: ".doc_img_slider",
  };

  var testimonialImgSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    // asNavFor: ".doc_testimonial_slider",
    arrows: false,
    fade: true,
    focusOnSelect: true,
  }



  return (
    <div>
      <section className="doc_banner_area banner_creative1">
        <ul className="list-unstyled banner_shap_img">
          <li>
            <img src={banner4} alt="" />
          </li>
          <li>
            <img src={banner1} alt="" />
          </li>
          <li>
            <img src={banner3} alt="" />
          </li>
          <li>
            <img src={banner2} alt="" />
          </li>
          <li>
            <img
              data-parallax='{"x": -180, "y": 80, "rotateY":2000}'
              src={pulse1}
              alt=""
            />
          </li>
          <li>
            <img
              data-parallax='{"x": -50, "y": -160, "rotateZ":200}'
              src={pulse2}
              alt=""
            />
          </li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="container">
          <div className="doc_banner_content">
            <h2 className="wow fadeInUp">How can we help you?</h2>
            <p className="wow fadeInUp" data-wow-delay="0.2s">
              Search here to get answers to your questions
            </p>
            <form action="#" className="header_search_form">
              <div className="header_search_form_info">
                <div className="form-group">
                  <div className="input-wrapper">
                    <i className="icon_search"></i>
                    <input
                      type="search"
                      id="searchbox"
                      autoComplete="off"
                      name="search"
                      placeholder="Search"
                    />
                    <div className="header_search_form_panel">
                      <ul className="list-unstyled">
                        <li>
                          Help Desk
                          <ul className="list-unstyled search_item">
                            <li>
                              <span>Configuration</span>
                              <a href="#">How to edit host and port?</a>
                            </li>
                            <li>
                              <span>Configuration</span>
                              <a href="#">The dev Property</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Support
                          <ul className="list-unstyled search_item">
                            <li>
                              <span>Pages</span>
                              <a href="#">The asyncData Method</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Documentation
                          <ul className="list-unstyled search_item">
                            <li>
                              <span>Getting Started</span>
                              <a href="#">The asyncData Method</a>
                            </li>
                            <li>
                              <span>Getting Started</span>
                              <a href="#">The asyncData Method</a>
                            </li>
                            <li>
                              <span>Getting Started</span>
                              <a href="#">The asyncData Method</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    {/* <select
                      className="search-expand-types custom-select"
                      name="post_type"
                      id="search_post_type"
                    >
                      <option value="">All Docs </option>
                      <option value="manual_kb">Knowledge Base</option>
                      <option value="manual_documentation">
                        Documentation
                      </option>
                      <option value="manual_faq">FAQs</option>
                      <option value="forum">Forums</option>
                      <option value="manual_portfolio">Portfolio</option>
                      <option value="product">Products</option>
                    </select> */}
                  </div>
                </div>
              </div>
              {/* <div className="header_search_keyword">
                <span className="header-search-form__keywords-label">
                  Suggested Search:
                </span>
                <ul className="list-unstyled">
                  <li className="wow fadeInUp" data-wow-delay="0.2s">
                    <a href="#">guest users</a>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay="0.3s">
                    <a href="#">invoice</a>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay="0.4s">
                    <a href="#">security</a>
                  </li>
                </ul>
              </div> */}
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
