import React from 'react'

const Loader2 = () => {
    return (
        <div style={{
            height: "100%",
            minHeight: "50vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Loader2